import * as path from "path";

/**
 * Used to initialize your Svelte program, in conjunction with the `svelte_mount` view helper.
 *
 * First, in your Ruby code:
 *
 * ```
 * <%= svelte_mount "my/typescript/entry_point", props: {user: @user} %>
 * ```
 *
 * And then in your entry point:
 *
 * ```
 * import { MySvelteApp } from "svelte/my-svelte-lib.js";
 * import SvelteMount from "lib/SvelteMount";
 *
 * document.addEventListener("DOMContentLoaded", () => {
 *   const svelteProgram = new SvelteMount("my/typescript/entry_point").init(MySvelteApp);
 * });
 * ```
 */
export default class SvelteMount<Props> {
  private _props?: Props;
  private _node?: HTMLElement;

  constructor(private _modulePath: string, private _withAmplitudeLogging: boolean = true) {
    this._modulePath = `svelte/${_modulePath}`;
  }

  init(svelteModule: SvelteModule<Props>): any {
    if (process.env.NODE_ENV === "development") {
      console.groupCollapsed("Mounting Svelte Program");
      if (this.node) {
        console.log("node: ", this.node.id);
      } else {
        console.log("no node parsed");
      }
      if (this.props) {
        console.log("props: ", this.props);
      } else {
        console.log("no props parsed");
      }
      console.groupEnd();
    }

    try {
      return new svelteModule({
        target: this.node,
        props: this.props,
      });
    } catch (e) {
      console.trace(e);
      if (e.name !== "Svelte Mount Error") {
        throw createMountError(e.message);
      } else {
        throw e;
      }
    }
  }

  get props(): Readonly<Props> {
    if (this._props) {
      return this._props;
    }

    const props = this.node.dataset.esSvelteProps;

    if (!props) {
      throw createMountError(
        "Failed to parse Svelte props. Make sure to use `svelte_mount` in your view."
      );
    }

    const parsedProps = JSON.parse(props);
    if (this._withAmplitudeLogging) {
      this._props = { ...parsedProps, logAmplitude } as Props;
    } else {
      this._props = parsedProps as Props;
    }
    return this._props;
  }

  private get node() {
    if (this._node) {
      return this._node;
    }

    const mountPointId = mountPointIdFromModulePath(this._modulePath);
    const mountNode = document.getElementById(mountPointId);

    if (!mountNode) {
      throw createMountError(
        `Failed to find svelte mount point "${mountPointId}". Make sure to use \`svelte_mount\` in your view.`
      );
    }
    this._node = mountNode;
    return this._node;
  }
}

export interface SvelteModule<Props> {
  new (options: { target: HTMLElement | null; props: Props }): any;
}

function logAmplitude(name: string, data?: any) {
  if (process.env.NODE_ENV === "development") {
    console.groupCollapsed(`Logging Analytics from Svelte: ${name}`);
    console.log("Event data: ", data);
    console.groupEnd();
  }
  try {
    window.amplitudeTeacherInstance().logEvent(name, data);
  } catch (e) {
    console.log("Error logging event: ", e);
  }
}

// Transforms module path to the id of the mount node, generated by the Rails svelte_mount helper.
// Example:
// `app/javascript/packs/foo/bar.ts`
// is converted to
// `svelte-mount-point-foo-bar`
function mountPointIdFromModulePath(modulePath: string): string {
  const moduleSuffix = modulePath
    .replace("app/javascript/packs/", "")
    .replace(path.extname(modulePath), "")
    .replace(/\//g, "-")
    .toLowerCase();
  return `svelte-mount-point-${moduleSuffix}`;
}

function createMountError(message: string): Error {
  const error = new Error(message);
  error.name = "Svelte Mount Error";
  return error;
}
